import React from 'react';
import './CardsIconeTestoFooter.scss';
import '../../css/plugins/slick-custom.scss';
import Title from '../../molecules/title/title';
import { Wrapper } from '../../utils/components';
import { getCommonOrganismData } from '../../utils/functions';
import classNames from 'classnames';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import withPreview from '../../utils/with-preview';
import CardIconaTesto from './CardIconaTesto';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';

const CardsIconeTesto = ({ data, considerMenuSpace }) => {
  const cardsIconeTesto = data;

  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    title,
    titleHeadingTag: titleTag,
    titleIconId,
    marginTop,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const cards = cardsIconeTesto.cards;
  const cardsNumber = cards.length;

  const footerLabel = cardsIconeTesto?.footer?.content?.footerText?.value;
  const footerHTMLContent =
    cardsIconeTesto?.footer?.content?.htmlFooterContent?.value;
  const footerTelephoneNumber =
    cardsIconeTesto?.footer?.content?.telephoneNumber?.value;

  const hasFooter =
    !!footerLabel || !!footerHTMLContent || !!footerTelephoneNumber;

  return (
    <Wrapper
      data-module-code='C-104'
      data-module-name='cards-icone-testo-footer'
      className={classNames(`cards-icone-testo-footer ${marginTop}`, {
        'cards-icone-testo-footer--colored-background':
          backgroundColor !== 'transparent',
      })}
      tag={htmlTagWrapper}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      <div
        className='cards-icone-testo-footer__container wrapper'
        style={{
          '--bgWrapper': `var(--${cardsIconeTesto?.backgroundColorWrapper?.value})`,
        }}
      >
        {(title || titleIconId) && (
          <Title
            tag={titleTag}
            titleIconId={titleIconId}
            titleIconThickness={titleIconThickness}
          >
            {title}
          </Title>
        )}
        <div className='cards-icone-testo-footer__wrapper'>
          <div className='cards-icone-testo-footer__wrapper-card'>
            {cards?.map((card, i) => (
              <CardIconaTesto
                key={i}
                data={card}
                className={classNames(`card-icona-testo`, {
                  'card-icona-testo--fullWidth':
                    (i + 1 === cardsNumber && (i + 1) % 2 !== 0) ||
                    (i + 1 === cardsNumber && cardsNumber === 1),
                })}
              />
            ))}
          </div>
          {hasFooter && (
            <div
              className='cards-icone-testo-footer__footer'
              style={{
                '--bgFooter': `var(--${cardsIconeTesto?.footer?.content?.backgroundColorFooter?.value})`,
              }}
            >
              <span className='cards-icone-testo-footer__footer-label'>
                {footerLabel}
              </span>
              <div className='box-editorial'>
                <ContenutoHtmlInner rawContent={footerHTMLContent} />
              </div>
              <a
                className='cards-icone-testo-footer__footer-phone'
                href={`tel:${footerTelephoneNumber}`}
                title='Numero telefonico'
              >
                {footerTelephoneNumber}
              </a>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default withPreview(CardsIconeTesto);
