import classNames from 'classnames';
import React from 'react';
import IconDots from '../../atoms/icon-dots/IconDots';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';
import { Wrapper } from '../../utils/components';

function CardIconaTesto(props) {
  const card = props.data.content;

  return (
    <div
      className={classNames(`${props.className}`, {
        'card-icona-testo--left-icon': card?.iconPosition?.value[0] === 'left',
        'card-icona-testo--top-icon': card?.iconPosition?.value[0] === 'top',
      })}
      data-submodule-name='card-icona-testo'
      style={{ '--bgCard': `var(--${card?.backgroundColorCard.value})` }}
    >
      {card?.iconSvgIdCard?.value && (
        <div className='card-icona-testo__icona'>
          <IconDots
            icon={card?.iconSvgIdCard?.value}
            thickness={
              card?.iconSvgIdCard?.content?.iconThicknessCard?.value?.[0]
            }
          />
        </div>
      )}
      <div className='card-icona-testo__wrapper-text'>
        {card.cardTitle.value && (
          <Wrapper
            className='card-icona-testo__titolo'
            tag={card?.headingTitleCard?.value}
          >
            {card?.cardTitle?.value}
          </Wrapper>
        )}
        {card?.htmlContent?.value && (
          <div className='card-icona-testo__testo box-editorial'>
            <ContenutoHtmlInner rawContent={card?.htmlContent?.value} />
          </div>
        )}
      </div>
    </div>
  );
}

export default CardIconaTesto;
